
// @ts-nocheck


export const localeCodes =  [
  "de-DE",
  "en-US",
  "es-ES"
]

export const localeLoaders = {
  "de-DE": [{ key: "../src/locales/single/de-DE.json", load: () => import("../src/locales/single/de-DE.json" /* webpackChunkName: "locale__app_src_locales_single_de_DE_json" */), cache: true }],
  "en-US": [{ key: "../src/locales/single/en-US.json", load: () => import("../src/locales/single/en-US.json" /* webpackChunkName: "locale__app_src_locales_single_en_US_json" */), cache: true }],
  "es-ES": [{ key: "../src/locales/single/es-ES.json", load: () => import("../src/locales/single/es-ES.json" /* webpackChunkName: "locale__app_src_locales_single_es_ES_json" */), cache: true }]
}

export const vueI18nConfigs = [
  () => import("../src/config/i18n.config.mts?hash=8c2b6d08&config=1" /* webpackChunkName: "__src_config_i18n_config_mts_8c2b6d08" */)
]

export const nuxtI18nOptions = {
  "experimental": {
    "localeDetector": "",
    "switchLocalePathLinkSSR": false,
    "autoImportTranslationFunctions": false
  },
  "bundle": {
    "compositionOnly": true,
    "runtimeOnly": false,
    "fullInstall": true,
    "dropMessageCompiler": false
  },
  "compilation": {
    "jit": true,
    "strictMessage": true,
    "escapeHtml": false
  },
  "customBlocks": {
    "defaultSFCLang": "json",
    "globalSFCScope": false
  },
  "vueI18n": "./src/config/i18n.config.mts",
  "locales": [
    {
      "code": "de-DE",
      "name": "Deutsch",
      "files": [
        "locales/single/de-DE.json"
      ]
    },
    {
      "code": "en-US",
      "name": "English",
      "files": [
        "locales/single/en-US.json"
      ]
    },
    {
      "code": "es-ES",
      "name": "Español",
      "files": [
        "locales/single/es-ES.json"
      ]
    }
  ],
  "defaultLocale": "de-DE",
  "defaultDirection": "ltr",
  "routesNameSeparator": "___",
  "trailingSlash": false,
  "defaultLocaleRouteNameSuffix": "default",
  "strategy": "no_prefix",
  "lazy": true,
  "langDir": "locales/single",
  "detectBrowserLanguage": false,
  "differentDomains": false,
  "baseUrl": "",
  "dynamicRouteParams": false,
  "customRoutes": "page",
  "pages": {},
  "skipSettingLocaleOnNavigate": false,
  "types": "composition",
  "debug": false,
  "parallelPlugin": false,
  "i18nModules": []
}

export const normalizedLocales = [
  {
    "code": "de-DE",
    "name": "Deutsch",
    "files": [
      {
        "path": "locales/single/de-DE.json"
      }
    ]
  },
  {
    "code": "en-US",
    "name": "English",
    "files": [
      {
        "path": "locales/single/en-US.json"
      }
    ]
  },
  {
    "code": "es-ES",
    "name": "Español",
    "files": [
      {
        "path": "locales/single/es-ES.json"
      }
    ]
  }
]

export const NUXT_I18N_MODULE_ID = "@nuxtjs/i18n"
export const parallelPlugin = false
export const isSSG = false

export const DEFAULT_DYNAMIC_PARAMS_KEY = "nuxtI18n"
export const DEFAULT_COOKIE_KEY = "i18n_redirected"
export const SWITCH_LOCALE_PATH_LINK_IDENTIFIER = "nuxt-i18n-slp"
