import { default as index0nd1weGJHvMeta } from "/app/src/pages/account/index.vue?macro=true";
import { default as indexwgywxERjK9Meta } from "/app/src/pages/admin/index.vue?macro=true";
import { default as offersFFkkP6ORkbMeta } from "/app/src/pages/admin/offers.vue?macro=true";
import { default as salesXCWuVsdkxFMeta } from "/app/src/pages/admin/sales.vue?macro=true";
import { default as login_45emailsyb9h2GCg6Meta } from "/app/src/pages/auth/login-email.vue?macro=true";
import { default as login_45pweAVV3JGBdgMeta } from "/app/src/pages/auth/login-pw.vue?macro=true";
import { default as loginOk5zcM8cXjMeta } from "/app/src/pages/auth/login.vue?macro=true";
import { default as dashboardLd3YXvnTkBMeta } from "/app/src/pages/dashboard.vue?macro=true";
import { default as imprintOX6qerXhoTMeta } from "/app/src/pages/imprint.vue?macro=true";
import { default as _91id_93wHVfCqHat2Meta } from "/app/src/pages/leads/[id].vue?macro=true";
import { default as indexrs8oi6qJbIMeta } from "/app/src/pages/leads/index.vue?macro=true";
import { default as typestIvlLWrCBBMeta } from "/app/src/pages/leads/types.vue?macro=true";
import { default as _91id_938xMk3MS31mMeta } from "/app/src/pages/offers/[id].vue?macro=true";
import { default as indexBaC9yurnmbMeta } from "/app/src/pages/offers/index.vue?macro=true";
import { default as privacy3wcvBua6OkMeta } from "/app/src/pages/privacy.vue?macro=true";
import { default as settings4uvkikcWGMMeta } from "/app/src/pages/settings.vue?macro=true";
import { default as termsXCu4sSphCUMeta } from "/app/src/pages/terms.vue?macro=true";
import { default as nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467__64unocss_43reset_640_4659_462_async_45validator_644YpShTSesepMeta } from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.2_async-validator@4._dsmi6gzyzfbxjnssnzwfxjjwwa/node_modules/nuxt/dist/pages/runtime/component-stub?macro=true";
import { default as nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467__64unocss_43reset_640_4659_462_async_45validator_644YpShTSesep } from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.2_async-validator@4._dsmi6gzyzfbxjnssnzwfxjjwwa/node_modules/nuxt/dist/pages/runtime/component-stub";
export default [
  {
    name: index0nd1weGJHvMeta?.name ?? "account",
    path: index0nd1weGJHvMeta?.path ?? "/account",
    meta: index0nd1weGJHvMeta || {},
    alias: index0nd1weGJHvMeta?.alias || [],
    redirect: index0nd1weGJHvMeta?.redirect,
    component: () => import("/app/src/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: indexwgywxERjK9Meta?.name ?? "admin",
    path: indexwgywxERjK9Meta?.path ?? "/admin",
    meta: indexwgywxERjK9Meta || {},
    alias: indexwgywxERjK9Meta?.alias || [],
    redirect: indexwgywxERjK9Meta?.redirect,
    component: () => import("/app/src/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: offersFFkkP6ORkbMeta?.name ?? "admin-offers",
    path: offersFFkkP6ORkbMeta?.path ?? "/admin/offers",
    meta: offersFFkkP6ORkbMeta || {},
    alias: offersFFkkP6ORkbMeta?.alias || [],
    redirect: offersFFkkP6ORkbMeta?.redirect,
    component: () => import("/app/src/pages/admin/offers.vue").then(m => m.default || m)
  },
  {
    name: salesXCWuVsdkxFMeta?.name ?? "admin-sales",
    path: salesXCWuVsdkxFMeta?.path ?? "/admin/sales",
    meta: salesXCWuVsdkxFMeta || {},
    alias: salesXCWuVsdkxFMeta?.alias || [],
    redirect: salesXCWuVsdkxFMeta?.redirect,
    component: () => import("/app/src/pages/admin/sales.vue").then(m => m.default || m)
  },
  {
    name: login_45emailsyb9h2GCg6Meta?.name ?? "auth-login-email",
    path: login_45emailsyb9h2GCg6Meta?.path ?? "/auth/login-email",
    meta: login_45emailsyb9h2GCg6Meta || {},
    alias: login_45emailsyb9h2GCg6Meta?.alias || [],
    redirect: login_45emailsyb9h2GCg6Meta?.redirect,
    component: () => import("/app/src/pages/auth/login-email.vue").then(m => m.default || m)
  },
  {
    name: login_45pweAVV3JGBdgMeta?.name ?? "auth-login-pw",
    path: login_45pweAVV3JGBdgMeta?.path ?? "/auth/login-pw",
    meta: login_45pweAVV3JGBdgMeta || {},
    alias: login_45pweAVV3JGBdgMeta?.alias || [],
    redirect: login_45pweAVV3JGBdgMeta?.redirect,
    component: () => import("/app/src/pages/auth/login-pw.vue").then(m => m.default || m)
  },
  {
    name: loginOk5zcM8cXjMeta?.name ?? "auth-login",
    path: loginOk5zcM8cXjMeta?.path ?? "/auth/login",
    meta: loginOk5zcM8cXjMeta || {},
    alias: loginOk5zcM8cXjMeta?.alias || [],
    redirect: loginOk5zcM8cXjMeta?.redirect,
    component: () => import("/app/src/pages/auth/login.vue").then(m => m.default || m)
  },
  {
    name: dashboardLd3YXvnTkBMeta?.name ?? "dashboard",
    path: dashboardLd3YXvnTkBMeta?.path ?? "/dashboard",
    meta: dashboardLd3YXvnTkBMeta || {},
    alias: dashboardLd3YXvnTkBMeta?.alias || [],
    redirect: dashboardLd3YXvnTkBMeta?.redirect,
    component: () => import("/app/src/pages/dashboard.vue").then(m => m.default || m)
  },
  {
    name: imprintOX6qerXhoTMeta?.name ?? "imprint",
    path: imprintOX6qerXhoTMeta?.path ?? "/imprint",
    meta: imprintOX6qerXhoTMeta || {},
    alias: imprintOX6qerXhoTMeta?.alias || [],
    redirect: imprintOX6qerXhoTMeta?.redirect,
    component: () => import("/app/src/pages/imprint.vue").then(m => m.default || m)
  },
  {
    name: _91id_93wHVfCqHat2Meta?.name ?? "leads-id",
    path: _91id_93wHVfCqHat2Meta?.path ?? "/leads/:id()",
    meta: _91id_93wHVfCqHat2Meta || {},
    alias: _91id_93wHVfCqHat2Meta?.alias || [],
    redirect: _91id_93wHVfCqHat2Meta?.redirect,
    component: () => import("/app/src/pages/leads/[id].vue").then(m => m.default || m)
  },
  {
    name: indexrs8oi6qJbIMeta?.name ?? "leads",
    path: indexrs8oi6qJbIMeta?.path ?? "/leads",
    meta: indexrs8oi6qJbIMeta || {},
    alias: indexrs8oi6qJbIMeta?.alias || [],
    redirect: indexrs8oi6qJbIMeta?.redirect,
    component: () => import("/app/src/pages/leads/index.vue").then(m => m.default || m)
  },
  {
    name: typestIvlLWrCBBMeta?.name ?? "leads-types",
    path: typestIvlLWrCBBMeta?.path ?? "/leads/types",
    meta: typestIvlLWrCBBMeta || {},
    alias: typestIvlLWrCBBMeta?.alias || [],
    redirect: typestIvlLWrCBBMeta?.redirect,
    component: () => import("/app/src/pages/leads/types.vue").then(m => m.default || m)
  },
  {
    name: _91id_938xMk3MS31mMeta?.name ?? "offers-id",
    path: _91id_938xMk3MS31mMeta?.path ?? "/offers/:id()",
    meta: _91id_938xMk3MS31mMeta || {},
    alias: _91id_938xMk3MS31mMeta?.alias || [],
    redirect: _91id_938xMk3MS31mMeta?.redirect,
    component: () => import("/app/src/pages/offers/[id].vue").then(m => m.default || m)
  },
  {
    name: indexBaC9yurnmbMeta?.name ?? "offers",
    path: indexBaC9yurnmbMeta?.path ?? "/offers",
    meta: indexBaC9yurnmbMeta || {},
    alias: indexBaC9yurnmbMeta?.alias || [],
    redirect: indexBaC9yurnmbMeta?.redirect,
    component: () => import("/app/src/pages/offers/index.vue").then(m => m.default || m)
  },
  {
    name: privacy3wcvBua6OkMeta?.name ?? "privacy",
    path: privacy3wcvBua6OkMeta?.path ?? "/privacy",
    meta: privacy3wcvBua6OkMeta || {},
    alias: privacy3wcvBua6OkMeta?.alias || [],
    redirect: privacy3wcvBua6OkMeta?.redirect,
    component: () => import("/app/src/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: settings4uvkikcWGMMeta?.name ?? "settings",
    path: settings4uvkikcWGMMeta?.path ?? "/settings",
    meta: settings4uvkikcWGMMeta || {},
    alias: settings4uvkikcWGMMeta?.alias || [],
    redirect: settings4uvkikcWGMMeta?.redirect,
    component: () => import("/app/src/pages/settings.vue").then(m => m.default || m)
  },
  {
    name: termsXCu4sSphCUMeta?.name ?? "terms",
    path: termsXCu4sSphCUMeta?.path ?? "/terms",
    meta: termsXCu4sSphCUMeta || {},
    alias: termsXCu4sSphCUMeta?.alias || [],
    redirect: termsXCu4sSphCUMeta?.redirect,
    component: () => import("/app/src/pages/terms.vue").then(m => m.default || m)
  },
  {
    name: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467__64unocss_43reset_640_4659_462_async_45validator_644YpShTSesepMeta?.name ?? undefined,
    path: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467__64unocss_43reset_640_4659_462_async_45validator_644YpShTSesepMeta?.path ?? "/",
    meta: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467__64unocss_43reset_640_4659_462_async_45validator_644YpShTSesepMeta || {},
    alias: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467__64unocss_43reset_640_4659_462_async_45validator_644YpShTSesepMeta?.alias || [],
    redirect: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467__64unocss_43reset_640_4659_462_async_45validator_644YpShTSesepMeta?.redirect,
    component: nuxt_643_4611_462__64parcel_43watcher_642_464_461__64types_43node_6420_4612_467__64unocss_43reset_640_4659_462_async_45validator_644YpShTSesep
  }
]