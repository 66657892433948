import revive_payload_client_E2ylFtUXHI from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.2_async-validator@4._dsmi6gzyzfbxjnssnzwfxjjwwa/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import vuetify_i18n_date_YdF5OIVNUh from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_rollup@4.14.2_typescript@5.4.5_vite@5.2.8_@types+node@20.12.7_sass_qh53pzf3e3fzysom6szg3txd3e/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n-date.mjs";
import vuetify_i18n_8nQRNzMnYI from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_rollup@4.14.2_typescript@5.4.5_vite@5.2.8_@types+node@20.12.7_sass_qh53pzf3e3fzysom6szg3txd3e/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-i18n.mjs";
import vuetify_icons_dQ83JK3rqZ from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_rollup@4.14.2_typescript@5.4.5_vite@5.2.8_@types+node@20.12.7_sass_qh53pzf3e3fzysom6szg3txd3e/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-icons.mjs";
import vuetify_client_hints_client_IlgtbmFhC4 from "/app/node_modules/.pnpm/vuetify-nuxt-module@0.14.1_rollup@4.14.2_typescript@5.4.5_vite@5.2.8_@types+node@20.12.7_sass_qh53pzf3e3fzysom6szg3txd3e/node_modules/vuetify-nuxt-module/dist/runtime/plugins/vuetify-client-hints.client.mjs";
import unhead_bpvmXFzM6M from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.2_async-validator@4._dsmi6gzyzfbxjnssnzwfxjjwwa/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_qZiHVXEc1S from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.2_async-validator@4._dsmi6gzyzfbxjnssnzwfxjjwwa/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import check_outdated_build_client_nrcXshnOla from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.2_async-validator@4._dsmi6gzyzfbxjnssnzwfxjjwwa/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_uiMTRSYVbs from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_rollup@4.14.2_typescript@5.4.5_vue@3.4.27_typescript@5.4.5_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_AQehnZlOzX from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.2_async-validator@4._dsmi6gzyzfbxjnssnzwfxjjwwa/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_O3HvdFAkDu from "/app/node_modules/.pnpm/vue3-perfect-scrollbar@2.0.0_vue@3.4.27_typescript@5.4.5_/node_modules/vue3-perfect-scrollbar/nuxt/dist/runtime/plugin.mjs";
import plugin_6Ph1iQzNHk from "/app/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import nuxt_plugin_IbYqc4gwCG from "/app/node_modules/.pnpm/nuxt-delay-hydration@1.3.3_rollup@4.14.2/node_modules/nuxt-delay-hydration/dist/runtime/nuxt-plugin.mjs";
import i18n_1ly5zkElne from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_rollup@4.14.2_vue@3.4.27_typescript@5.4.5_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_VwPmUbC1rw from "/app/node_modules/.pnpm/nuxt@3.11.2_@parcel+watcher@2.4.1_@types+node@20.12.7_@unocss+reset@0.59.2_async-validator@4._dsmi6gzyzfbxjnssnzwfxjjwwa/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import vuetify_F4zXYcNIwC from "/app/src/plugins/vuetify.ts";
import vuetify_nuxt_plugin_client_JOtFoHdxcp from "/app/.nuxt/vuetify-nuxt-plugin.client.mjs";
export default [
  revive_payload_client_E2ylFtUXHI,
  vuetify_i18n_date_YdF5OIVNUh,
  vuetify_i18n_8nQRNzMnYI,
  vuetify_icons_dQ83JK3rqZ,
  vuetify_client_hints_client_IlgtbmFhC4,
  unhead_bpvmXFzM6M,
  router_qZiHVXEc1S,
  check_outdated_build_client_nrcXshnOla,
  plugin_vue3_uiMTRSYVbs,
  components_plugin_KR1HBZs4kY,
  prefetch_client_AQehnZlOzX,
  plugin_O3HvdFAkDu,
  plugin_6Ph1iQzNHk,
  nuxt_plugin_IbYqc4gwCG,
  i18n_1ly5zkElne,
  chunk_reload_client_VwPmUbC1rw,
  vuetify_F4zXYcNIwC,
  vuetify_nuxt_plugin_client_JOtFoHdxcp
]