export const appHead = {"meta":[{"name":"viewport","content":"width=device-width, initial-scale=1"},{"charset":"utf-8"},{"content":"noindex, nofollow","hid":"robots","name":"robots"}],"link":[{"key":"mdi","rel":"stylesheet","href":"https://cdn.jsdelivr.net/npm/@mdi/font@5.x/css/materialdesignicons.min.css","type":"text/css","crossorigin":"anonymous"}],"style":[],"script":[{"body":true,"innerHTML":"window._mfq = window._mfq || []; (function() {var mf = document.createElement(\"script\"); mf.type = \"text/javascript\"; mf.defer = true; mf.src = \"//cdn.mouseflow.com/projects/97ee29e2-a4b1-4602-8ce0-877b269a9dfa.js\";document.getElementsByTagName(\"head\")[0].appendChild(mf);})();"}],"noscript":[],"htmlAttrs":{"style":"background-color: #111; color: #f0f0f0"},"title":"leadcloser"}

export const appLayoutTransition = {"mode":"in-out","name":"page"}

export const appPageTransition = {"mode":"out-in","name":"page"}

export const appBaseURL = "/"

export const appBuildAssetsDir = "/_nuxt/"

export const appCdnURL = ""

export const appViewTransition = false

export const appKeepalive = false

export const appRootId = "__nuxt"

export const appRootTag = "div"

export const appTeleportTag = "div"

export const appTeleportId = "teleports"

export const renderJsonPayloads = true

export const componentIslands = true

export const payloadExtraction = false

export const cookieStore = true

export const appManifest = true

export const remoteComponentIslands = false

export const selectiveClient = false

export const devPagesDir = null

export const devRootDir = null

export const devLogs = false

export const nuxtLinkDefaults = {"componentName":"NuxtLink"}

export const asyncDataDefaults = {"deep":true}

export const fetchDefaults = {}

export const vueAppRootContainer = '#__nuxt'

export const viewTransition = false