export type LeadStatus =
  'gebucht' |
  'qualifiziert' |
  'beraten' |
  'Angebot versendet' |
  'Risikovorabgefragt' |
  'abgeschlossen' |
  'reklamiert'
export interface LeadType {
  $id: string
  title: Date
  valueType: 'percentage' | 'currency'
  price: number
}

export type LeadRating = 0 | 0.5 | 1
export type LeadLike = 0 | 0.5 | 1

export interface Note {
  $createdAt?: Date
  $id?: string
  $updatedAt?: Date
  createdAt?: Date
  text: string
  type?: 'default' | 'callAttempt'
}
export interface State {
  $createdAt?: Date
  $id?: string
  $updatedAt?: Date
  createdAt?: Date
  level: number
  state: LeadStatus
  value?: string
  successfully?: boolean
  reason?: string
}

export interface CustomisedLead {
  $id?: string | null
  address?: string | null
  birthday?: Date | null
  postalCode?: string | null
  city?: string | null
  firstName?: string | null
  lastName?: string | null
  email?: string | null
  phone?: string | null
  phone2?: string | null
  salutation?: string | null
}

export interface Guidance {
  backgroundOfInquiry: string // Hintergrund der Anfrage / Stand Beratung
  customerType: 'schnell' | 'detailreich' | 'bedenklich' | 'unentschlossen' | 'sparsam' | 'sonstiges' // Kundentyp
  hasAlreadyInformed: string // Hat sich der Kunde bereits anderweitig informiert?
  previousInsuranceStatus: 'GKV pflicht' | 'GKV freiwillig' | 'PKV' | 'nicht versichert' // Wie vorversichert?
  previousInsurer: string // Wo vorversichert?
  monthlyPremium: number | string // mtl. Beitrag
  maritalStatus: 'ledig' | 'verheiratet' | 'geschieden' | 'verwittwet' // Wie ist der Familienstand?
  partnerInsuredAt: string // Wo Partner versichert?
  childrenDetails: string // Anzahl Kinder, Bemerkung z.B. bei wem versichert
  occupation: string // Beruf
  annualGrossIncome: number | string // Jahresbruttoeinkommen
  creditRating: 'positiv' |
  'negativ Schufa' |
  'negativ infoscore' |
  'negativ Schufa und infoscore' |
  'laufende Insolvenz' |
  'unbekannt' // Bonität
}

export interface Lead {
  $id: string
  address: string
  birthday: Date | string
  bookedAt: Date | string
  postalCode: string
  city: string
  avatarId: string
  firstName: string
  lastName: string
  type: LeadType
  $createdAt: string
  $databaseId: string
  $updatedAt: Date | string
  lastActiveAt: Date | string
  complaint?: 'pending' | 'cancelled' | 'accepted'
  complaints: any[]
  createdAt: Date | string
  deliveredAt: Date | string
  details: string
  email: string
  leadId: string
  notes: Note[]
  partnerId: string
  phone: string
  phone2: string
  professionalStatus: string
  userId: string
  referer: string
  salutation: string
  state: LeadStatus
  states: State[]
  tasks: Task[]
  customised: CustomisedLead
  rating: 0 | 0.5 | 1
  like: 0 | 0.5 | 1
  guidance: Guidance
  callReached: boolean
  salesPrice: number
  offers: Offer[]
}

export interface Task {
  $createdAt?: Date
  $id?: string
  $updatedAt?: Date
  title?: string
  description?: string | null
  priority?: 'low' | 'medium' | 'high'
  expirationAt?: Date | null
  status?: 'open' | 'in-progress' | 'completed' | 'canceled'
  type?: 'default' | 'call'
  leads?: Lead
}
export interface Offer {
  $createdAt?: Date
  $id?: string
  $updatedAt?: Date
  fileId?: string
  name?: string | null
  value?: number | null
  type: LeadType
  leads?: Lead
}

export const useLeadStore = defineStore('lead', () => {
  const leads = ref<Lead[]>([])
  const totalLeads = ref(0)

  const lead = ref<Lead>()
  const leadCustomised = computed(() => {
    if (lead.value?.$id) {
      return {
        address: lead.value.customised?.address || lead.value.address,
        birthday: lead.value.customised?.birthday || lead.value.birthday,
        city: lead.value.customised?.city || lead.value.city,
        email: lead.value.customised?.email || lead.value.email,
        firstName: lead.value.customised?.firstName || lead.value.firstName,
        lastName: lead.value.customised?.lastName || lead.value.lastName,
        phone: lead.value.customised?.phone || lead.value.phone,
        phone2: lead.value.customised?.phone2 || lead.value.phone2,
        postalCode: lead.value.customised?.postalCode || lead.value.postalCode,
        salutation: lead.value.customised?.salutation || lead.value.salutation,
      }
    }
  })

  const getLead = async (id: string) => {
    lead.value = await $fetch(`/api/v1/leads/${id}`)
  }

  const updateLeadValue = async (leadId: string, key: string, value: any) => {
    const updatedLead = await $fetch<Lead>(`/api/v1/leads/${leadId}`, {
      body: { [key]: value },
      method: 'PUT',
    })

    updateLeadData(updatedLead)
    //   // leads.value[index].$updatedAt = new Date()
  }

  const updateLeadCustomised = async (leadId: string, customised: CustomisedLead) => {
    const updatedLead = await $fetch<Lead>(`/api/v1/leads/${leadId}/customised`, {
      body: JSON.stringify(customised),
      method: 'PUT',
    })

    updateLeadData(updatedLead)
  }

  const updateLeadGuidance = async (leadId: string, guidance: Guidance) => {
    const updatedLead = await $fetch<Lead>(`/api/v1/leads/${leadId}/guidance`, {
      body: JSON.stringify(guidance),
      method: 'PUT',
    })

    updateLeadData(updatedLead)
  }

  const updateLeadNotes = async (leadId: string, note: Note) => {
    const updatedLead = await $fetch<Lead>(`/api/v1/leads/${leadId}/notes`, {
      body: note,
      method: note.$id ? 'PUT' : 'POST',
    })

    updateLeadData(updatedLead)
  }

  const deleteLeadNote = async (leadId: string, noteId: string) => {
    const updatedLead = await $fetch<Lead>(`/api/v1/leads/${leadId}/notes/${noteId}`, {
      method: 'DELETE',
    })

    updateLeadData(updatedLead)
  }

  const updateLeadStatus = async (leadId: string, newState: State) => {
    const updatedLead = await $fetch<Lead>(`/api/v1/leads/${leadId}/states`, {
      body: newState,
      method: 'PUT',
    })
    // console.log(updatedLead)

    updateLeadData(updatedLead)
  }

  const createLeadTask = async (leadId: string, task: Task) => {
    const updatedLead = await $fetch<Lead>(`/api/v1/leads/${leadId}/tasks`, {
      body: task,
      method: 'POST',
    })

    updateLeadData(updatedLead)
  }

  const updateLeadTask = async (leadId: string, newState: Task) => {
    const updatedLead = await $fetch<Lead>(`/api/v1/leads/${leadId}/tasks`, {
      body: newState,
      method: 'PUT',
    })

    updateLeadData(updatedLead)
  }
  const deleteLeadTask = async (leadId: string, taskId: string) => {
    const updatedLead = await $fetch<Lead>(`/api/v1/leads/${leadId}/tasks/${taskId}`, {
      method: 'DELETE',
    })

    updateLeadData(updatedLead)
  }

  const lastUpdated = ref(new Date())

  function updateLeadData(updatedLead: Lead) {
    if (lead.value?.$id === updatedLead.$id)
      lead.value = updatedLead

    if (useRightSidebarStore().lead?.$id === updatedLead.$id)
      useRightSidebarStore().getLead(updatedLead.$id)

    const index = leads.value.findIndex(m => m.$id === updatedLead.$id)
    if (index !== -1)
      leads.value[index] = updatedLead

    lastUpdated.value = new Date()
  }

  function $reset() {
    leads.value = []
  }

  return {
    $reset,
    createLeadTask,
    deleteLeadNote,
    deleteLeadTask,
    getLead,
    lastUpdated,
    lead,
    leadCustomised,
    leads,
    totalLeads,
    updateLeadCustomised,
    updateLeadGuidance,
    updateLeadNotes,
    updateLeadStatus,
    updateLeadTask,
    // delLead,
    updateLeadValue,
  }
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useLeadStore, import.meta.hot))
