export default defineNuxtPlugin((nuxtApp) => {
  // nuxtApp.hook('vuetify:before-create', ({ isDev, vuetifyOptions }) => {
  //   // eslint-disable-next-line node/prefer-global/process
  //   if (process.client && isDev)
  //     console.log('vuetify:plugin:hook', vuetifyOptions)
  // })
  // nuxtApp.hook('vuetify:ready', () => {
  //   // console.log('vuetify:ready')
  // })
  // nuxtApp.hook('vuetify:ssr-client-hints', ({ ssrClientHints }) => {
  //   console.log('vuetify:ssr-client-hints', ssrClientHints)
  // })
  // const { mobileBreakpoint } = useAppConfig()
  // nuxtApp.hook('vuetify:before-create', ({ vuetifyOptions }) => {
  //   vuetifyOptions.display ??= {}
  //   vuetifyOptions.display.mobileBreakpoint = mobileBreakpoint
  // })
})
