import {
  addDays,
  addHours,
  addMinutes,
  differenceInYears,
  format,
  formatDistance,
  formatDistanceToNow,
  isAfter,
  isBefore,
  isToday,
  isValid,
  isWithinInterval,
  isYesterday,
  parse,
  setHours,
  setMinutes,
  startOfDay,
  subDays,
  subHours,
  subMinutes,
} from 'date-fns'
import { de } from 'date-fns/locale'

const customLocale = {
  ...de,
  // formatDistance: (token, count: number, options) => {
  //   // Passen Sie diese Funktion an, um benutzerdefinierte Zeitstrings zurückzugeben
  //   const replacementMap = {
  //     aboutXHours: (count: number) => `${count}Std`,
  //     aboutXMonths: (count: number) => `etwa ${count}Mon`,
  //     aboutXYears: (count: number) => `etwa ${count}Jahr`,
  //     almostXYears: (count: number) => `fast ${count}Jahr`,
  //     halfAMinute: () => '30s',
  //     lessThanXMinutes: (count: number) => count === 1 ? '< 1m' : `< ${count}m`,
  //     lessThanXSeconds: (count: number) => count === 1 ? '< 1s' : `< ${count}s`,
  //     overXYears: (count: number) => `über ${count}J`,
  //     xDays: (count: number) => count === 1 ? '1T' : `${count}T`,
  //     xHours: (count: number) => `${count}Std`,
  //     xMinutes: (count: number) => `${count}m`,
  //     xMonths: (count: number) => `${count}Mon`,
  //     xSeconds: (count: number) => `${count}s`,
  //     xYears: (count: number) => `${count}J`,
  //     // Ergänzen Sie weitere Anpassungen nach Bedarf
  //   }

  //   const replacementFunction = replacementMap[token]
  //   if (replacementFunction)
  //     return replacementFunction(count)

  //   // Fallback für nicht behandelte Tokens
  //   return token
  // },
}
const customShortLocale = {
  ...de,
  formatDistance: (token, count: number, options) => {
    // Passen Sie diese Funktion an, um benutzerdefinierte Zeitstrings zurückzugeben
    const replacementMap = {
      aboutXHours: (count: number) => `${count}Std`,
      aboutXMonths: (count: number) => `etwa ${count}Mon`,
      aboutXYears: (count: number) => `etwa ${count}Jahr`,
      almostXYears: (count: number) => `fast ${count}Jahr`,
      halfAMinute: () => '30s',
      lessThanXMinutes: (count: number) => count === 1 ? '< 1m' : `< ${count}m`,
      lessThanXSeconds: (count: number) => count === 1 ? '< 1s' : `< ${count}s`,
      overXYears: (count: number) => `über ${count}J`,
      xDays: (count: number) => count === 1 ? '1T' : `${count}T`,
      xHours: (count: number) => `${count}Std`,
      xMinutes: (count: number) => `${count}m`,
      xMonths: (count: number) => `${count}Mon`,
      xSeconds: (count: number) => `${count}s`,
      xYears: (count: number) => `${count}J`,
      // Ergänzen Sie weitere Anpassungen nach Bedarf
    }

    const replacementFunction = replacementMap[token]
    if (replacementFunction)
      return replacementFunction(count)

    // Fallback für nicht behandelte Tokens
    return token
  },
}

export function useDateFns() {
  function untilNow(date: Date, options = { short: false }) {
    return formatDistanceToNow(
      new Date(date),
      {
        addSuffix: true,
        locale: options.short ? customShortLocale : customLocale,
      },
    )
  }
  function untilThen(date: Date) {
    return formatDistance(
      new Date(date),
      new Date(),
      {
        addSuffix: true,
        locale: customLocale,
      },
    )
  }

  function isYesterdayOrToday(date: Date) {
    return isYesterday(date) || isToday(date)
  }

  function calculateAge(date: Date) {
    return differenceInYears(
      new Date(),
      new Date(date),
    )
  }

  function formatDate(date: Date, dateFormat = 'EEEE, d. MMMM yyyy \'um\' HH:mm') {
    return format(date, dateFormat, { locale: de })
  }

  function addTimeToDate(date: Date, amount: number, unit: 'm' | 'h' | 'd' = 'm') {
    switch (unit) {
      case 'm':
        return addMinutes(date, amount)
      case 'h':
        return addHours(date, amount)
      case 'd':
        return addDays(date, amount)
      default:
        // Für unbekannte Einheiten wird das ursprüngliche Datum zurückgegeben
        return date
    }
  }

  function subTimeToDate(date: Date, amount: number, unit: 'm' | 'h' | 'd' = 'm') {
    switch (unit) {
      case 'm':
        return subMinutes(date, amount)
      case 'h':
        return subHours(date, amount)
      case 'd':
        return subDays(date, amount)
      default:
        // Für unbekannte Einheiten wird das ursprüngliche Datum zurückgegeben
        return date
    }
  }

  function isTimeSlotInFuture(from, to) {
    const now = new Date()
    const startOfDayNow = startOfDay(now)
    const startTime = setMinutes(setHours(startOfDayNow, from.hour), from.minute)
    const endTime = setMinutes(setHours(startOfDayNow, to.hour), to.minute)

    return isWithinInterval(now, { end: endTime, start: startTime }) || isBefore(now, startTime)
  }

  function formatTime(timeString: string) {
    const [hours, minutes] = timeString.split(':').map(Number)

    return `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`
  }

  function getDateWithTimeInFuture(amount: number, hour: number, minute: number): Date {
    let date = new Date()
    date = setHours(date, hour)
    date = setMinutes(date, minute)

    if (amount > 0)
      date = addDays(date, amount)

    return date
  }

  function changeTimeOfDate(date: Date, hour: number, minute: number): Date {
    return setMinutes(setHours(date, hour), minute)
  }

  function isTodayOrInFuture(date: Date) {
    const now = new Date()

    return isToday(date) || isAfter(date, now)
  }

  function addDayAndFormat(dateString: string): string {
    const originalDate = new Date(dateString)
    const newDate = addDays(originalDate, 1)

    return format(newDate, 'yyyy-MM-dd')
  }

  function isDateRangeContainingToday(startDateStr: string, endDateStr: string, format: string = 'yyyy-MM-dd'): boolean {
    const today = new Date()
    const startOfToday = startOfDay(today)

    const startDate = parse(startDateStr, format, new Date())
    const endDate = parse(endDateStr, format, new Date())

    return isWithinInterval(startOfToday, { end: endDate, start: startDate })
  }

  function parseDate(date: string, dateFormat = 'dd.MM.yyyy') {
    return parse(date, dateFormat, new Date())
  }

  function isValideDate(date: Date) {
    return isValid(date)
  }

  return {
    addDayAndFormat,
    addTimeToDate,
    calculateAge,
    changeTimeOfDate,
    formatDate,
    formatTime,
    getDateWithTimeInFuture,
    isDateRangeContainingToday,
    isTimeSlotInFuture,
    isTodayOrInFuture,
    isValideDate,
    isYesterdayOrToday,
    parseDate,
    subTimeToDate,
    untilNow,
    untilThen,
  }
}
