<script lang="ts" setup>
onMounted(() => {
  useAppSettingsStore().loadSettings()
})
</script>

<template>
  <NuxtLoadingIndicator />
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>

<style lang="scss">
</style>
