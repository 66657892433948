export const useRightSidebarStore = defineStore('rightSidebar', () => {
  const lead = ref<Lead>()
  const leadCustomised = computed(() => {
    if (lead.value?.$id) {
      return {
        address: lead.value.customised?.address || lead.value.address,
        birthday: lead.value.customised?.birthday || lead.value.birthday,
        city: lead.value.customised?.city || lead.value.city,
        email: lead.value.customised?.email || lead.value.email,
        firstName: lead.value.customised?.firstName || lead.value.firstName,
        lastName: lead.value.customised?.lastName || lead.value.lastName,
        phone: lead.value.customised?.phone || lead.value.phone,
        phone2: lead.value.customised?.phone2 || lead.value.phone2,
        postalCode: lead.value.customised?.postalCode || lead.value.postalCode,
        salutation: lead.value.customised?.salutation || lead.value.salutation,
      }
    }
  })
  const getLead = async (id: string) => {
    lead.value = await $fetch(`/api/v1/leads/${id}`)
  }

  const isOpen = ref(false)

  function setIsOpen(value: boolean) {
    isOpen.value = value
  }

  function toggleIsOpen() {
    isOpen.value = !isOpen.value
  }

  const isInfo = ref(false)

  function setIsInfo(value: boolean) {
    isInfo.value = value
  }
  const isInfoLocked = ref(true)
  const isInfoPermanent = ref(false)
  watch(isInfoPermanent, (value) => {
    if (!value)
      isInfo.value = true
  })
  watch(isOpen, (value) => {
    if (!value)
      isInfo.value = false
  })

  const isSchedule = ref(true)
  function setIsSchedule(value: boolean) {
    isSchedule.value = value
  }

  const title = computed(() => {
    return !lead.value
      ? 'Tagesplan'
      // eslint-disable-next-line max-len
      : `${leadCustomised.value?.firstName} ${leadCustomised.value?.lastName}, ${useDateFns().calculateAge(leadCustomised.value?.birthday as Date)} Jahre`
  })

  const isAssistantMenu = ref(false)

  function $reset() {
    // lead.value = undefined
  }

  function openRightSidebar(id: string) {
    useLeadStore().getLead(id)
    setIsInfo(false)
    setIsOpen(true)
    // setIsSchedule(false)
  }

  return {
    $reset,
    getLead,
    isAssistantMenu,
    isInfo,
    isInfoLocked,
    isInfoPermanent,
    isOpen,
    isSchedule,
    lead,
    leadCustomised,
    openRightSidebar,
    setIsInfo,
    setIsOpen,
    setIsSchedule,
    title,
    toggleIsOpen,
  }
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useRightSidebarStore, import.meta.hot))
