<script setup lang="ts">
definePageMeta({ layout: 'auth' })
const theme = useTheme()
const opacity = computed(() => theme.global.current.value.dark ? 1 : 0.2)

const error = useError()

function handleError() {
  clearError({
    redirect: '/dashboard',
  })
}
</script>

<template>
  <v-app class="h-screen bg-gradiant">
    <!-- <UiColorModeButton class="header-buttons" /> -->
    <v-main class="overflow-y-auto h-100 w-100">
      <v-container fluid class="px-6 fill-height">
        <v-row class=" fill-height" no-gutters align="center" justify="center">
          <v-col class="fill-height d-flex align-center justify-center" cols="12" md="3">
            <v-card
              class="aurora-card rounded-xl w-100"
            >
              <div class="pa-4 aurora-inner w-100 d-flex flex-column align-center">
                <UiLogo
                  class="mt-6 mb-16"
                  color="rgb(var(--v-theme-primary))"
                  background-color="rgb(var(--v-theme-surface))"
                  border-color="rgb(var(--v-theme-primary))"
                  border-width="2px"
                />
                <template v-if="error?.statusCode === 404">
                  <h1>404</h1>
                  <p class="text-no-wrap">
                    Seite konnte nicht gefunden werden.
                  </p>
                </template>
                <template v-else-if="error">
                  <h1>Leider</h1>
                  <p class="text-no-wrap">
                    <strong>{{ error?.message }}</strong>
                  </p>
                  <p class="text-no-wrap">
                    Es scheint, als wäre etwas kaputt gegangen.
                  </p>
                  <p class="text-no-wrap">
                    Tut mir leid deswegen.
                  </p>
                </template>
                <template v-else>
                  <div class="d-flex align-center justify-center w-100">
                    <Icon
                      class="text-primary" name="svg-spinners:pulse-multiple" size="52"
                    />
                  </div>
                </template>
                <p class="mt-8 text-no-wrap">
                  Geh zurück zu deinem
                  <NuxtLink class="cursor-pointer" @click="handleError">
                    Dashboard.
                  </NuxtLink>
                </p>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<style lang="scss" scoped>
.aurora-card {
  background-image: radial-gradient(
      at top left,
      rgba(var(--v-theme-primary-darken-1), v-bind(opacity)),
      transparent
    ),
    radial-gradient(
      at top right,
      rgba(var(--v-theme-secondary), v-bind(opacity)),
      transparent
    ),
    radial-gradient(
      at bottom left,
      rgba(var(--v-theme-secondary-darken-1), v-bind(opacity)),
      transparent
    ) !important;
  background-size: 400% !important;
  background-position: center center;
  animation: aurora 40s infinite;
}

.aurora-inner {
  background: radial-gradient(
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.25)
  );
  background-size: 400%;
  animation: aurora 28s infinite;
}

.text-gradient {
  background: linear-gradient(
    45deg,
    rgba(var(--v-theme-background), 1),
    rgba(var(--v-theme-surface), 1) 100%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

@keyframes aurora {
  0% {
    background-position: left top;
  }
  25% {
    background-position: right top;
  }
  50% {
    background-position: right bottom;
  }
  75% {
    background-position: left bottom;
  }
  100% {
    background-position: left top;
  }
}
</style>
