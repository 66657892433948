export interface Settings {
  appearance: Appearance
  dailyTasks: DailyTasks
  mail: Mail
}

export interface Appearance {
  darkMode: boolean
}

export interface DailyTasks {
  timeSlots: TimeSlot[]
}

export interface TimeSlot {
  from: From
  label: string
  to: To
}

export interface From {
  hour: number
  minute: number
}

export interface To {
  hour: number
  minute: number
}

export interface Mail {
  smtp: Smtp
}

export interface Smtp {
  host: string
  password: string
  port: string
  secure: boolean
  username: string
}

export const useAppSettingsStore = defineStore('appSettings', () => {
  const settingsId = ref<string | null>(null)
  const settings = ref<Settings>({
    appearance: {
      darkMode: true,
    },
    dailyTasks: {
      timeSlots: [
        { from: { hour: 7, minute: 0 }, label: 'morgens', to: { hour: 11, minute: 0 } },
        { from: { hour: 13, minute: 0 }, label: 'nachmittags', to: { hour: 16, minute: 0 } },
        { from: { hour: 18, minute: 0 }, label: 'abends', to: { hour: 21, minute: 0 } },
      ],
    },
    mail: {
      smtp: {
        host: '',
        password: '',
        port: '',
        secure: false,
        username: '',
      },
    },
  })
  // Funktionen zur Aktualisierung der verschiedenen Einstellungen...
  function updateTimeSlot(slotIndex: string | number, label: any, fromHour: any, fromMinute: any, toHour: any, toMinute: any) {
    settings.value.dailyTasks.timeSlots[slotIndex as number] = {
      from: { hour: fromHour, minute: fromMinute },
      label,
      to: { hour: toHour, minute: toMinute },
    }
  }

  function addTimeSlot() {
    const lastSlot = settings.value.dailyTasks.timeSlots[settings.value.dailyTasks.timeSlots.length - 1]
    const newFromHour = lastSlot ? lastSlot.to.hour : 0
    const newFromMinute = lastSlot ? lastSlot.to.minute : 0

    // Berechne den neuen to-Wert basierend auf dem from-Wert
    let newToHour = newFromHour + 1
    // TODO: besseres händling bei stunde 23, dann auf 59 minuten setzen
    const newToMinute = newFromMinute

    // Wenn newToHour 24 erreicht, setze es zurück auf 0
    if (newToHour >= 24)
      newToHour = 0

    // Generiere ein einzigartiges Label
    let newLabel = 'Neuer Slot'
    let counter = 2
    while (settings.value.dailyTasks.timeSlots.some(slot => slot.label === newLabel))
      newLabel = `Neuer Slot ${counter++}`

    settings.value.dailyTasks.timeSlots.push({
      from: { hour: newFromHour, minute: newFromMinute },
      label: newLabel,
      to: { hour: newToHour, minute: newToMinute },
    })
  }

  // Funktion zum Löschen eines TimeSlot anhand des Index
  function deleteTimeSlot(index: number) {
    const taskStore = useTaskStore()

    settings.value.dailyTasks.timeSlots.splice(index, 1)
    taskStore.getOpenCalls()
  }

  function updateSmtpSettings(newSettings) {
    settings.value.mail.smtp = { ...settings.value.mail.smtp, ...newSettings }
  }

  function toggleDarkMode() {
    settings.value.appearance.darkMode = !settings.value.appearance.darkMode
  }

  function $reset() {
    settings.value = {
      appearance: {
        darkMode: false,
      },
      dailyTasks: {
        timeSlots: [
          { from: { hour: 9, minute: 0 }, label: 'Morning', to: { hour: 12, minute: 0 } },
          { from: { hour: 12, minute: 1 }, label: 'Afternoon', to: { hour: 17, minute: 0 } },
          { from: { hour: 17, minute: 1 }, label: 'Evening', to: { hour: 21, minute: 0 } },
        ],
      },
      mail: {
        smtp: {
          host: '',
          password: '',
          port: '',
          secure: false,
          username: '',
        },
      },
    }
  }

  const updateSettings = async () => {
    const appSettings = await $fetch<any>(`/api/v1/settings`, {
      baseURL: useRuntimeConfig().public.base.url,
      body: {
        $id: settingsId.value,
        settings: settings.value,
      },
      method: 'PUT',
    })

    settingsId.value = appSettings.$id
  }

  const isSettingsLoaded = ref(false)
  const changeIsSettingsLoaded = useDebounceFn(() => {
    isSettingsLoaded.value = true
  }, 500, { maxWait: 1000 })

  const loadSettings = async () => {
    const data = await $fetch<{ documents: any[], total: number }>('/api/v1/settings', {
      baseURL: useRuntimeConfig().public.base.url,
      method: 'GET',
    })

    if (data?.$id) {
      settingsId.value = data.$id
      settings.value = data.settings
    }

    changeIsSettingsLoaded()
  }

  watchDebounced(
    () => settings,
    () => {
      if (isSettingsLoaded.value)
        updateSettings()
    },
    { debounce: 500, deep: true, maxWait: 1000 },
  )

  // watch(() => settings, () => {
  //   // console.log('settings changed', settings.value.appearance)
  //   useDebounceFn(() => {

  // console.log('changed!')
  //     saveSettings()
  //   }, 1000)
  // }, { deep: true })

  return {
    $reset,
    addTimeSlot,
    deleteTimeSlot,
    loadSettings,
    settings,
    toggleDarkMode,
    updateSmtpSettings,
    updateTimeSlot,
  }
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useAppSettingsStore, import.meta.hot))
