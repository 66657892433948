export default defineNuxtRouteMiddleware(async (to) => {
  const excludes = ['/auth', '/offers/', '/imprint']

  const protectedRoute = () => {
    return !excludes.some(route => to.path.startsWith(route))
  }

  if (protectedRoute()) {
    try {
      const account = await $fetch('/api/v1/account', {
        baseURL: useRuntimeConfig().public.base.url,
      })

      if (typeof account === 'string' || !account)
        return navigateTo('/auth/login')
    }
    catch (error) {
      // console.log('check auth error', error)

      // return navigateTo('/auth/login')
    }
  }
})
