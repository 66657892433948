import { isBefore, isWithinInterval, set } from 'date-fns'

interface Reminder {
  date: Date
  from: {
    hour: number
    minute: number
  }
  time: string
  to: {
    hour: number
    minute: number
  }
}

// Angepasste Funktion zur Generierung der Erinnerungen für einen Lead
function generateRemindersForLead() {
  const now = new Date()
  const settings = useAppSettingsStore().settings

  return settings.dailyTasks.timeSlots.reduce((reminders: Reminder[], slot) => {
    if (useDateFns().isTimeSlotInFuture(slot.from, slot.to)) {
      reminders.push({
        date: now,
        from: slot.from,
        time: slot.label,
        to: slot.to,
      })
    }

    return reminders
  }, [])
}

export const useTaskStore = defineStore('task', () => {
  // Hilfsfunktion, um den TimeSlot basierend auf der aktuellen Zeit zu finden oder den nächsten verfügbaren, wenn keine Leads vorhanden sind
  const findCurrentOrNextTimeSlot = () => {
    const now = new Date()
    const timeSlots = useAppSettingsStore().settings?.dailyTasks.timeSlots.sort((a, b) => {
      const timeA = set(now, { hours: a.from.hour, minutes: a.from.minute }).getTime()
      const timeB = set(now, { hours: b.from.hour, minutes: b.from.minute }).getTime()

      return timeA - timeB
    })

    // console.log(timeSlots)

    if (timeSlots) {
      let currentOrNextTimeSlot = null

      for (const slot of timeSlots) {
        const fromTime = set(now, { hours: slot.from.hour, minutes: slot.from.minute })
        const toTime = set(now, { hours: slot.to.hour, minutes: slot.to.minute })

        if (isWithinInterval(now, { end: toTime, start: fromTime })) {
          currentOrNextTimeSlot = slot
          break
        }
        else if (isBefore(now, fromTime)) {
          currentOrNextTimeSlot = slot
          break
        }
      }

      return currentOrNextTimeSlot
    }
  }

  const tasks = ref<Task[]>([])
  const openTasksCount = computed(
    () => tasks.value?.filter(t => ['open', 'in-progress']
      .includes(t.status as string)).length || 0,
  )

  const getTasks = async () => {
    const query = [
      // Query.equal('type', 'call'),
      Query.orderDesc('$createdAt'),
      // Query.select(['expirationAt', 'status', '$id', 'title']),
    ]

    const response = await $fetch<{ documents: Task[], total: number }>('/api/v1/tasks', {
      baseURL: useRuntimeConfig().public.base.url,
      params: {
        query,
        // page: page.value,
        // search: search.value,
      },
    })
    // console.log({ response })

    tasks.value = response?.documents || []
  }

  const openCalls = ref({})

  const getOpenCalls = async () => {
    const appSettingsStore = useAppSettingsStore()
    await appSettingsStore.loadSettings()

    const query = [
      Query.orderDesc('bookedAt'),
      Query.equal('state', ['gebucht']),
    ]

    const { documents } = await $fetch<{ documents: Lead[], total: number }>('/api/v1/leads', {
      method: 'GET',
      params: {
        query,
      },
    })

    const currentOrNextTimeSlot = findCurrentOrNextTimeSlot()

    // Initialisiere TimeSlots mit zusätzlichen Details
    const newOpenCalls: { [key: string]: any } = {}
    appSettingsStore.settings.dailyTasks.timeSlots.forEach((slot) => {
      newOpenCalls[slot.label] = {
        from: slot.from,
        label: slot.label,
        leads: [],
        to: slot.to,
      }
    })

    documents.forEach((document) => {
      const lead = document // Da `documents` direkt Leads sind, verwenden wir `document` als Lead
      const notes = lead.notes || [] // Sicherstellen, dass `notes` existiert

      // Prüfe, ob der Lead in dem aktuellen oder nächsten TimeSlot aufgrund von Notizen ausgeschlossen werden sollte
      const excludeFromAllSlots = notes.some(note => note.type === 'callAttempt' && note.text === 'Lead erreicht')
      let excludeFromCurrentSlot = false
      if (currentOrNextTimeSlot) {
        excludeFromCurrentSlot = notes
          .some(note => note.type === 'callAttempt' && note.text === 'Lead nicht erreicht' && useDateFns()
            .isTimeSlotInFuture(currentOrNextTimeSlot.from, currentOrNextTimeSlot.to))
      }

      // Ordne Leads den entsprechenden TimeSlots zu, es sei denn, sie sind ausgeschlossen
      const reminders = generateRemindersForLead()
      reminders.forEach((reminder) => {
        if (!excludeFromAllSlots && !(excludeFromCurrentSlot && reminder.time === currentOrNextTimeSlot.label)) {
          if (newOpenCalls[reminder.time]) {
            newOpenCalls[reminder.time].leads.push({
              $id: lead.$id,
              avatar: lead.avatarId,
              bookedAt: lead.bookedAt,
              name: `${lead.firstName} ${lead.lastName}`,
              phone: lead.phone,
              phone2: lead.phone2,
              type: lead.type,
            })
          }
        }
      })
    })

    // Filtere TimeSlots, die Leads enthalten, und aktualisiere `openCalls.value`
    const filteredOpenCalls = Object.keys(newOpenCalls).reduce((acc: any, key) => {
      if (newOpenCalls[key].leads.length > 0)
        acc[key] = newOpenCalls[key]

      return acc
    }, {})

    openCalls.value = filteredOpenCalls
  }

  // Computed Eigenschaft für die Anzahl der Leads im aktuellen oder nächsten verfügbaren TimeSlot
  const openCallsCount = computed(() => {
    const currentOrNextTimeSlot = findCurrentOrNextTimeSlot()

    if (!currentOrNextTimeSlot
      || !openCalls.value[currentOrNextTimeSlot.label])
      return 0

    return openCalls.value[currentOrNextTimeSlot.label].leads.length
  })

  function $reset() {
    tasks.value = []
  }

  watch(() => useLeadStore().lastUpdated, () => {
    // getTasks()
    getOpenCalls()
  })

  return {
    $reset,
    getOpenCalls,
    getTasks,
    openCalls,
    openCallsCount,
    openTasksCount,
    tasks,
  }
})

// make sure to pass the right store definition, `useAuth` in this case.
if (import.meta.hot)
  import.meta.hot.accept(acceptHMRUpdate(useTaskStore, import.meta.hot))
